<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-btn @click="$router.go(-1)" class="no-active" icon>
        <v-icon>$vuetify.icons.arrow_left</v-icon>
      </v-btn>
      <v-toolbar-title>Статистика просмотров</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-container v-if="!loadPage">
      <cond-stat class="mb-4"></cond-stat>
      <v-row class="align-center">
        <v-col v-if="books.length > 0" cols="12" sm="5">
          <v-select
            v-model="selectBook"
            :items="books"
            item-text="title"
            item-value="id"
            label="Книга"
            prepend-icon="$vuetify.icons.bookshelf"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="4">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            :min-width="$vuetify.breakpoint.xsOnly ? '100%' : '290px'"
          >
            <!-- :return-value.sync="date" -->
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dateRangeText"
                label="Дата"
                prepend-icon="$vuetify.icons.calendar"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :first-day-of-week="1"
              v-model="date"
              no-title
              type="month"
              :full-width="$vuetify.breakpoint.xsOnly"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">Закрыть</v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(date)"
                >Принять</v-btn
              >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="3">
          <v-btn dark color="primary" @click="sortStatistics">Искать</v-btn>
        </v-col>
      </v-row>
      <v-row v-if="!statistics || statistics.length == 0" align="center">
        <no-content route-name title="Данных нет"></no-content>
      </v-row>
      <v-data-table
        v-else
        :headers="headersTable"
        :items="statistics"
        :items-per-page="15"
        class=""
        item-key="id_table"
      >
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CondStat from "./CondStat.vue";

export default {
  components: {
    CondStat,
  },
  data: () => ({
    headersTable: [
      {
        text: "День",
        align: "start",
        sortable: false,
        value: "day_date",
      },
      { text: "Книга", value: "book_title" },
      { text: "Просмотры", value: "count" },
      // { text: "Закончили", value: "count_end" },
    ],
    selectBook: "all",
    date: "",
    menu: false,
  }),
  methods: {
    getStatistics() {
      //event.preventDefault();
      this.loading = true;
      let app = this;
      this.$store.dispatch("cabinet_statistics/getStatisticsViews", {
        book_id: app.selectBook,
        date: app.date,
      });
    },
    goPage(nextPage) {
      if (nextPage != this.$route.query.page) {
        this.$router.push({
          name: this.$route.name,
          //   params: { id: this.bookId },
          query: { book_id: this.selectBook, page: nextPage, date: this.date },
        });
      }
    },
    sortStatistics() {
      if (
        this.date != this.$route.query.date ||
        this.selectBook != this.$route.query.book_id
      ) {
        this.$router.push({
          name: this.$route.name,
          query: { book_id: this.selectBook, date: this.date },
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      statistics: "cabinet_statistics/statistics",
      books: "cabinet_statistics/books",
    }),
    dateRangeText() {
      //   if (this.date.length > 1) {
      //     return this.date.join(" ~ ");
      //   }
      return this.date;
    },
  },
  mounted() {
    this.selectBook = this.$route.query.book_id
      ? this.$route.query.book_id
      : "all";
    if (this.selectBook != "all") {
      this.selectBook = +this.selectBook;
    }
    this.date = this.$route.query.date
      ? this.$route.query.date
      : new Date().toISOString().substr(0, 7);

    this.getStatistics();
  },
};
</script>
