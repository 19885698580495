<template>
  <v-card v-if="condStat">
    <v-card-title class="headline">Общие данные</v-card-title>

    <v-card-text>
      <v-row>
        <v-col class="py-0" cols="12" md="6">
          <div class="cond-stat-item" v-if="condStat.count_purchases">
            <span>Покупок:</span>
            <span class="count">{{ +condStat.count_purchases }}</span>
          </div>
          <div class="cond-stat-item">
            <span>Отметок "Мне нравится":</span>
            <span class="count">{{ +condStat.count_likes }}</span>
          </div>
          <div class="cond-stat-item">
            <span>Комментариев:</span>
            <span class="count">{{ +condStat.count_comments }}</span>
          </div>
        </v-col>
        <v-col class="py-0" cols="12" md="6">
          <div class="cond-stat-item">
            <span>Прочтений:</span>
            <span class="count">{{ +condStat.count_views }}</span>
          </div>
          <div class="cond-stat-item" v-if="+condStat.count_views_end">
            <span>Завершенных прочтений:</span>
            <span class="count">{{ +condStat.count_views_end }}</span>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      condStat: "cabinet_statistics/condStat",
    }),
  },
};
</script>
